import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { usePostToken } from '../../networking/useAuth';
import { useCustomAuth } from './RelyantAuthProvider';
import Loading from '../../components/base/Loading';

const Callback = () => {
  const { handleCustomAuthentication } = useCustomAuth();
  const { isLoading, statusCode, data, call } = usePostToken();

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    console.log({ isLoading, statusCode, data });
    if (data && statusCode) {
      console.log(data);
      if (data.access_token && data.refresh_token) {
        handleCustomAuthentication(data);
        navigate('/');
      }
    }
  }, [data]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const code = params.get('code');
    // const state = params.get('state');
    if (code) {
      call({ code, redirect_uri: window.location.origin + '/callback' });
    }
  }, []);

  if (isLoading) {
    return <Loading />;
  }

  return null;
};

export default Callback;
