import React, { useEffect } from 'react';

import Loading from '../../components/base/Loading';
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import { FormView } from '../../components/base/FormView';
import { useUpdateUser } from '../../networking/useUser';
// import withRelyantAuthenticationRequired from '../Login/withRelyantAuthenticationRequired';

export const UserView = () => {
  const { user } = useAuth0();
  console.log({ user });
  const { isLoading, statusCode, data: updateResponse, call } = useUpdateUser();

  const displayMapping = {
    email: 'Email',
    name: 'Name',
  };

  const onUpdate = (data) => {
    call(data);
  };

  useEffect(() => {
    if (statusCode && statusCode !== 200) {
      alert('Something went wrong with the update, please contact Relyant');
      return;
    }
  }, [updateResponse]);

  return (
    <>
      {user.sub.startsWith('google-oauth2') ? (
        <FormView headerForm={'User'} data={user} displayMapping={displayMapping} />
      ) : (
        <FormView
          headerForm={'User'}
          data={user}
          displayMapping={displayMapping}
          onUpdate={onUpdate}
          isLoading={isLoading}
        />
      )}
    </>
  );
};

export default withAuthenticationRequired(UserView, {
  onRedirecting: () => <Loading />,
});
// export default withRelyantAuthenticationRequired(UserView);
