import React, { ReactNode } from 'react';
import { Avatar, List, Skeleton } from 'antd';
import { Text } from './Text';

export type ListItemEntry = {
  header: string;
  subheader?: string;
  content?: string;
  href?: string;
  avatar?: {
    icon?: ReactNode;
    url?: string;
    shape?: 'circle' | 'square';
    size?: 'large' | 'small';
  };
  loading: boolean;
  buttons?: ReactNode[];
  onClick?: () => void;
};

export const ListItems = ({
  items,
  itemLayout = 'vertical',
}: {
  items: ListItemEntry[];
  itemLayout?: 'vertical' | 'horizontal';
}) => {
  return (
    <List
      itemLayout={itemLayout}
      dataSource={items}
      renderItem={(item) => (
        <List.Item actions={item.buttons}>
          <Skeleton avatar title={false} loading={item.loading} active>
            <List.Item.Meta
              avatar={
                item.avatar ? (
                  <Avatar
                    shape={item.avatar?.shape}
                    src={item.avatar?.url}
                    size={item.avatar?.size || 'small'}
                    style={{
                      borderRadius: 1,
                      backgroundColor: 'transparent',
                    }}
                    icon={item.avatar?.icon}
                  />
                ) : null
              }
              title={
                <a href={item.href} onClick={item.onClick}>
                  {item.header}
                </a>
              }
              description={item.subheader}
            />
            <Text value={item.content}></Text>
          </Skeleton>
        </List.Item>
      )}
    />
  );
};
