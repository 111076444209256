import React, { useEffect, useState } from 'react';

import { Text } from '../../components/base/Text';
import { ListItemEntry, ListItems } from '../../components/base/ListItems';
import withRelyantAuthenticationRequired from '../Login/withRelyantAuthenticationRequired';
import { GmailIcon } from '../../icons/Gmail';
import { Button } from '../../components/base/Button';
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import Loading from '../../components/base/Loading';

import { getConfig } from '../../config';
import { useDeleteIdentities, useGetIdentities, usePostIdentities } from '../../networking/useAuth';
import { isNonEmpty } from '../../utils/arrayUtils';
import { OutlookIcon } from '../../icons/Outlook';

import './IntegrationView.css';

const config = getConfig();

export const IntegrationView = () => {
  const { loginWithPopup, user, getIdTokenClaims } = useAuth0();
  const [integrations, setIntegrations] = useState<ListItemEntry[]>([]);

  const [isLoadingUser, setIsLoadingUser] = useState(false);
  const { isLoading, data, call } = useGetIdentities();
  const { isLoading: isLoadingPost, data: postIdentityResponse, call: callPostIdentity } = usePostIdentities();
  const { isLoading: isLoadingDelete, data: deleteIdentityResponse, call: callDeleteIdentity } = useDeleteIdentities();

  const displayMapping = {
    'google-oauth2': {
      header: 'Gmail',
      subheader: 'Process emails from Google and extract info from body and attachments',
      loading: isLoading,
      avatar: {
        icon: <GmailIcon />,
        shape: 'square' as 'square',
        size: 'large' as 'large',
      },
    },
    // 'outlook-oauth2': {
    //   header: 'Outlook',
    //   subheader: 'Process emails from Microsoft and extract info from body and attachments',
    //   loading: isLoading,
    //   avatar: {
    //     icon: <OutlookIcon />,
    //     shape: 'square' as 'square',
    //     size: 'large' as 'large',
    //   },
    // },
  };

  useEffect(() => {
    if (postIdentityResponse) {
      // Necessary to restore the old user in the browser
      window.location.reload();
    }
  }, [postIdentityResponse]);

  useEffect(() => {
    if (deleteIdentityResponse) {
      // Necessary to restore the old user in the browser
      window.location.reload();
    }
  }, [deleteIdentityResponse]);

  useEffect(() => {
    if (data && isNonEmpty(data)) {
      const integrationEntries: ListItemEntry[] = [];
      for (let identity of data) {
        if (displayMapping[identity.provider]) {
          integrationEntries.push({
            ...displayMapping[identity.provider],
            buttons: [
              <Button
                text={identity.enabled ? 'Enabled' : 'Enable'}
                hoveredText={identity.enabled ? 'Disable' : null}
                key={identity.provider}
                onClick={() => getConsent({ isEnabled: identity.enabled, connection: identity.provider })}
                className={identity.enabled ? 'integration-button-enabled' : null}
              />,
            ],
          });
        }
      }

      setIntegrations(integrationEntries);
    }
  }, [data]);

  useEffect(() => {
    (async () => {
      await call();
    })();
  }, []);

  const isLoadingPage = isLoadingPost || isLoadingDelete;
  if (isLoadingUser || isLoadingPage) {
    return <Loading />;
  }

  const getConsent = async ({ isEnabled, connection }: { isEnabled: boolean; connection: string }) => {
    if (isEnabled) {
      // eslint-disable-next-line no-restricted-globals
      if (confirm(`Are you sure you want to disable it?`)) {
        setIsLoadingUser(true);
        callDeleteIdentity({ provider: connection });
      } else {
        return;
      }
    } else {
      const oldUser = user;
      await loginWithPopup({
        authorizationParams: {
          connection,
          // The Gmail toggle in Auth0 dashboard is ON.
          // This causes to ask for an aggressive permission when logging in.
          // We can create a custom connection in Auth0, invoke the google custom authorization URL
          // with more granular scopes and update the Gmail toggle to OFF.
          // With this approach the email prompt will be removed during login,
          // and in the Integration page it will only ask for those permissions
          scope: 'openid email profile offline_access',
          audience: config.audience ? config.audience : null,
          // prompt: 'consent',
        },
      });
      const newIdentity = await getIdTokenClaims();

      setIsLoadingUser(true);
      callPostIdentity({
        provider: connection,
        primary_user: oldUser.sub,
        secondary_user: newIdentity.sub,
      });
    }
  };

  return (
    <div className="flex-column" style={{ padding: '3vw', overflowY: 'auto', gap: 24 }}>
      <Text value={'Integrations'} className="header1" />
      {isLoading ? (
        <Loading />
      ) : isNonEmpty(integrations) ? (
        <ListItems items={integrations} itemLayout="horizontal"></ListItems>
      ) : null}
    </div>
  );
};

// export default withRelyantAuthenticationRequired(IntegrationView);

export default withAuthenticationRequired(IntegrationView, {
  // onRedirecting: () => <Loading />,
});
