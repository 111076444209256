import React, { CSSProperties, ReactNode } from 'react';

export const Text = ({
  value,
  className,
  style,
  onClick,
}: {
  value: string | ReactNode;
  className?: string;
  style?: CSSProperties;
  onClick?: () => void;
}) => {
  return (
    <div
      className={className}
      style={{
        whiteSpace: 'pre-line',
        ...style,
      }}
      onClick={onClick}
      onKeyDown={() => {}}
      role="button"
      tabIndex={0}
    >
      {value}
    </div>
  );
};
