import React, { useState } from 'react';
import { Form, Input, Popconfirm, Table, Tooltip, Typography } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/es/table';

type EditableTableProps<T> = {
  tableData: T[];
  tableColumns: ColumnsType<T>;
  addEditColumn?: boolean;
  onSave?: (id, item) => void;
  onDelete?: (id) => void;
};

interface EditableCellProps<T> {
  editing: boolean;
  dataIndex: keyof T;
  title: string;
  inputType: 'text';
  record: T;
  index: number;
  children: React.ReactNode;
}

const EditableCell = <T extends object>({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}: EditableCellProps<T>) => {
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex as string}
          style={{ margin: 0 }}
          rules={[
            {
              required: true,
              message: `Please enter ${title.toLowerCase()}!`,
            },
          ]}
        >
          <Input style={{ width: '100%' }} />
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

/**
 * Generic table to display, edit and delete data
 *
 * @param
 * @returns
 */
const EditableTable = <T extends { id: string } & object>({
  tableData,
  tableColumns,
  addEditColumn,
  onSave,
  onDelete,
}: EditableTableProps<T>) => {
  const [form] = Form.useForm();
  const [data, setData] = useState<T[]>(tableData);
  const [editingKey, setEditingKey] = useState('');

  const isEditing = (record: T) => record.id === editingKey;

  const edit = (record: Partial<T> & { id: React.Key }) => {
    form.setFieldsValue({ ...record });
    setEditingKey(record.id);
  };

  const cancel = () => {
    setEditingKey('');
  };

  const save = async (key: React.Key) => {
    try {
      const row = (await form.validateFields()) as T;

      const newData = [...data];
      const index = newData.findIndex((item) => key === item.id);

      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, {
          ...item,
          ...row,
        });
        const fieldsToUpdate = {
          ...item,
          ...row,
        };
        delete fieldsToUpdate.id;
        onSave(item.id, fieldsToUpdate);

        setData(newData);
        setEditingKey('');
      } else {
        newData.push(row);
        setData(newData);
        setEditingKey('');
      }
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
    }
  };

  const handleDelete = (id: React.Key) => {
    const newData = data.filter((item) => item.id !== id);
    onDelete(id);
    setData(newData);
  };

  const nonOperationColumns = tableColumns.filter((col) => col.title !== 'operation');
  if (addEditColumn) {
    nonOperationColumns.push({
      title: '',
      dataIndex: 'operation',
      render: (_: any, record: T) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Typography.Link onClick={() => save(record.id)} style={{ marginInlineEnd: 8, color: 'blue' }}>
              Save
            </Typography.Link>
          </span>
        ) : (
          <div className="flex-row" style={{ gap: 8 }}>
            <Typography.Link disabled={editingKey !== ''} onClick={() => edit(record)} style={{ color: 'blue' }}>
              <Tooltip className="clickable" title="Edit">
                <EditOutlined></EditOutlined>
              </Tooltip>
            </Typography.Link>
            <Popconfirm className="clickable" title="Sure to delete?" onConfirm={() => handleDelete(record.id)}>
              <Tooltip className="clickable" title="Delete">
                <DeleteOutlined style={{ color: 'red' }}></DeleteOutlined>
              </Tooltip>

              {/* <span style={{ color: 'red' }}>Delete</span> */}
            </Popconfirm>
          </div>
        );
      },
    });
  }

  const mergedColumns = nonOperationColumns.map((col) => {
    if (!('editable' in col) || !col.editable) {
      return col;
    }
    return {
      ...col,
      //   width: 120,
      onCell: (record: T) => {
        // Type narrow the column to ensure `dataIndex` exists
        if ('dataIndex' in col) {
          return {
            record,
            inputType: 'text',
            dataIndex: col.dataIndex,
            title: col.title,
            editing: isEditing(record),
          };
        }
        return {};
      },
    };
  });

  return (
    <Form form={form} component={false}>
      <Table<T>
        components={{
          body: { cell: EditableCell },
        }}
        bordered
        dataSource={data}
        columns={mergedColumns as ColumnsType<T>}
        pagination={{ onChange: cancel }}
      />
    </Form>
  );
};

export default EditableTable;
