import React, { useEffect, useState } from 'react';
import { Text } from '../../components/base/Text';
import { TextInput } from '../../components/base/TextInput';
import { Flow, Step, useGetFlowPredefinedSteps } from '../../networking/useFlow';
import { isNonEmpty } from '../../utils/arrayUtils';
import { Button } from '../../components/base/Button';
import { Steps } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { FlowStep } from './FlowStep';
import { MarkdownText } from '../../components/base/MarkdownText';

export const FlowForm = ({
  entity,
  isEditing,
  setEntity,
  onSave,
}: {
  entity: Flow;
  isEditing?: boolean;
  setEntity?;
  onSave?;
}) => {
  const { isLoading: isLoadingSteps, data, call } = useGetFlowPredefinedSteps();
  const [flowSteps, setFlowSteps] = useState<Step[]>([]);

  useEffect(() => {
    if (data && isNonEmpty(data)) {
      setFlowSteps(data);
    }
  }, [data]);

  useEffect(() => {
    call();
  }, []);

  const getStep = (stepSlug: string): Step => {
    for (let step of flowSteps) {
      if (step.slug === stepSlug) return step;
    }
  };

  const simpleFields = {
    name: 'Name',
    description: 'Description',
  };

  return (
    <>
      {/* Upper part with title and description */}
      {Object.keys(simpleFields).map((key, index) => {
        const label = simpleFields[key];
        const value = entity[key];

        return (
          <div key={index} className="flex-row" style={{ gap: 12 }}>
            <Text style={{ alignSelf: 'center', width: 200, fontWeight: 700 }} value={`${label}:`} />

            {!isEditing ? (
              <Text value={value}></Text>
            ) : (
              <TextInput
                value={value as string}
                setText={(text) =>
                  setEntity((entity) => ({
                    ...entity,
                    [key]: text,
                  }))
                }
                style={{ maxWidth: 512 }}
              />
            )}
          </div>
        );
      })}

      {/* Lower part with Steps */}
      <div className="flex-column" style={{ gap: 12 }}>
        <MarkdownText
          style={{ color: 'grey' }}
          markdownText={`Every flow is constituted by an **event** and a list of actions. \\
          When the event is triggered, all the actions will be executed automatically in order.`}
        />

        <Text style={{ fontWeight: 700 }} value={`Steps:`} />

        {isLoadingSteps ? (
          <div>Loading steps...</div>
        ) : !isNonEmpty(entity.step_slugs) && isNonEmpty(flowSteps) && isEditing ? (
          <Button
            text="Create new Event and Action"
            style={{ marginTop: 16 }}
            onClick={() => {
              setEntity((flow) => ({
                ...flow,
                step_slugs: [flowSteps[0].slug, flowSteps[1].slug],
              }));
            }}
          />
        ) : !isNonEmpty(entity.step_slugs) ? (
          <div>No steps found</div>
        ) : (
          <>
            <Steps
              size="small"
              direction="vertical"
              items={entity.step_slugs.map((stepSlug, index) => {
                const step = getStep(stepSlug);
                if (!step) {
                  if (isNonEmpty(flowSteps)) {
                    const updatedStepSlugs = entity.step_slugs.filter((_, i) => i !== index);
                    setEntity((flow) => ({
                      ...flow,
                      step_slugs: updatedStepSlugs,
                    }));
                  }
                  return null;
                }
                return {
                  icon: (
                    <div style={{ ...circleStyle, backgroundColor: step.type === 'event' ? 'green' : 'blue' }}>
                      {index + 1}
                    </div>
                  ),
                  title: (
                    <FlowStep
                      key={index}
                      step={step}
                      isEditing={isEditing}
                      predefinedSteps={flowSteps}
                      setStep={(newStepSlug) => {
                        setEntity((flow) => ({
                          ...flow,
                          step_slugs: entity.step_slugs.map((oldStep, i) => (i === index ? newStepSlug : oldStep)),
                        }));
                      }}
                      deleteStep={() => {
                        const updatedStepSlugs = entity.step_slugs.filter((_, i) => i !== index);
                        setEntity((flow) => ({
                          ...flow,
                          step_slugs: updatedStepSlugs,
                        }));
                      }}
                    />
                  ),
                };
              })}
            />
            {isEditing ? (
              <>
                <div
                  className="flex-row clickable"
                  style={{ textAlign: 'left', gap: 8, fontWeight: 700 }}
                  onClick={() => {
                    setEntity((flow) => ({
                      ...flow,
                      step_slugs: [...flow.step_slugs, flowSteps[1].slug],
                    }));
                  }}
                  onKeyDown={() => {}}
                  role="button"
                  tabIndex={0}
                >
                  <PlusOutlined />
                  <Text value="Create new Action" />
                </div>
                <Button text="Save flow" style={{ marginTop: 16 }} onClick={onSave} />
              </>
            ) : null}
          </>
        )}
      </div>
    </>
  );
};

const circleStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: '16px',
  width: '24px',
  height: '24px',
  borderRadius: '50%',
  backgroundColor: '#1890ff',
  color: 'white',
  fontWeight: 'bold',
};
