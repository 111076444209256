import React, { createContext, useContext, useState } from 'react';
import { Auth0Provider, LocalStorageCache } from '@auth0/auth0-react';
import { getConfig } from '../../config';
import { useSessionState } from '../../utils/usePersistedState';

const CustomAuthContext = createContext({
  isAuthenticated: false,
  user: null,
  handleCustomAuthentication: (tokens) => {},
});
const config = getConfig();

// eslint-disable-next-line react/prop-types
const RelyantAuthProvider = ({ children, ...props }) => {
  const [isAuthenticated, setIsAuthenticated] = useSessionState<boolean>('isAuthenticated', false);
  const [user, setUser] = useSessionState('user', null);

  const fetchUserProfile = async (accessToken) => {
    const response = await fetch(`https://${config.domain}/userinfo`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    if (!response.ok) {
      throw new Error('Failed to fetch user profile');
    }
    const userProfile = await response.json();
    // console.log({ userProfile });
    setUser(userProfile);
  };

  const handleCustomAuthentication = (tokens) => {
    // TODO: This is mainly for backwards compatibility with Auth0 SDK. Consider to remove this.
    const localStorageCache = new LocalStorageCache();
    localStorageCache.set('access_token', tokens.access_token);
    localStorageCache.set('refresh_token', tokens.refresh_token);
    localStorageCache.set('id_token', tokens.id_token);

    fetchUserProfile(tokens.access_token);
    setIsAuthenticated(true);
  };

  return (
    <CustomAuthContext.Provider value={{ isAuthenticated, user, handleCustomAuthentication }}>
      <Auth0Provider
        domain={config.domain}
        clientId={config.clientId}
        authorizationParams={{
          redirect_uri: window.location.origin,
          audience: config.audience ? config.audience : null,
          // The Gmail toggle in Auth0 dashboard is ON.
          // This causes to ask for an aggressive permission when logging in.
          // We can create a custom connection in Auth0, invoke the google custom authorization URL
          // with more granular scopes and update the Gmail toggle to OFF.
          // With this approach the email prompt will be removed during login,
          // and in the Integration page it will only ask for those permissions
          scope: 'openid email profile offline_access',
          access_type: 'offline',
          prompt: 'consent',
        }}
        // skipRedirectCallback={true}
      >
        {children}
      </Auth0Provider>
    </CustomAuthContext.Provider>
  );
};

const useCustomAuth = () => useContext(CustomAuthContext);

export { RelyantAuthProvider, useCustomAuth };
