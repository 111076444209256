import React, { CSSProperties, useState } from 'react';
import { Text } from './Text';
import { Button as AntButton } from 'antd';

export const Button = ({
  type = 'primary',
  text,
  hoveredText,
  disabled,
  onClick,
  style,
  isLoading,
  className,
  props,
}: {
  type?: string;
  text: string;
  hoveredText?: string;
  disabled?: boolean;
  onClick?: (event) => void;
  style?: CSSProperties;
  isLoading?: boolean;
  className?;
  props?;
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const commonStyles = {
    borderRadius: 8,
    paddingTop: 2,
    paddingBottom: 2,
    paddingLeft: 24,
    paddingRight: 24,
  };
  const buttonPrimaryStyle: CSSProperties = {
    ...commonStyles,
    backgroundColor: 'transparent',
    ...style,
  };
  const buttonSecondaryStyle: CSSProperties = {
    ...commonStyles,
    backgroundColor: !disabled ? '#1890ff' : null,
    color: 'white',
    ...style,
  };

  return (
    <AntButton
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      style={type === 'primary' ? buttonPrimaryStyle : buttonSecondaryStyle}
      onClick={onClick}
      disabled={disabled}
      loading={isLoading}
      className={className}
      {...props}
    >
      <Text style={{ alignItems: 'center' }} value={isHovered && hoveredText ? hoveredText : text}></Text>
    </AntButton>
  );
};
