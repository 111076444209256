import React, { useEffect, useState } from 'react';
import { Order, useDeleteOrder, useGetOrders, useUpdateOrder } from '../../networking/useOrder';
import { isNonEmpty } from '../../utils/arrayUtils';
// import withRelyantAuthenticationRequired from '../Login/withRelyantAuthenticationRequired';
import Loading from '../../components/base/Loading';
import { Text } from '../../components/base/Text';
import { formatDate } from 'date-fns';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import EditableTable from '../../components/base/Table';
import { ColumnsType } from 'antd/es/table';

const OrdersView = () => {
  const [orders, setOrders] = useState<Order[]>([]);
  const [colDefs, setColDefs] = useState<ColumnsType<Order>>([]);

  const { isLoading, data, call } = useGetOrders();
  const { data: dataUpdate, call: callUpdate } = useUpdateOrder();
  const { data: dataDelete, call: callDelete } = useDeleteOrder();

  const displayMapping = {
    order_date: 'Date',
    product_id: 'Product ID',
    sku: 'SKU',
    quantity: 'Quantity',
    unit_price: 'Unit price ($)',
    total_price: 'Total price ($)',
    // status: 'Status',
  };

  useEffect(() => {
    if (data && isNonEmpty(data.items)) {
      const ordersTrasformed = data.items.map((order) => {
        order['key'] = order.id;
        order['order_date'] = formatDate(order.order_date_utc, 'MM-dd-yyyy');
        return order;
      });

      const columnFields = Object.keys(displayMapping).map((col, index) => {
        return { dataIndex: col, key: col, title: displayMapping[col], editable: true };
      });
      setColDefs(columnFields);
      setOrders(ordersTrasformed);
    }
  }, [data]);

  useEffect(() => {
    (async () => {
      await call();
    })();
  }, []);

  const onSave = (id: string, item: Order) => {
    callUpdate({ entity_id: id, fields_to_update: item });
  };

  const onDelete = (id: string) => {
    callDelete({ entity_id: id });
  };

  return (
    <div style={{ padding: '3vw', overflowY: 'auto', gap: 24 }}>
      <Text value={'Orders'} className="header1" />
      <div style={{ paddingTop: '1vw' }}>
        {isLoading ? (
          <Loading />
        ) : isNonEmpty(orders) ? (
          <EditableTable<Order>
            tableData={orders}
            tableColumns={colDefs}
            addEditColumn={true}
            onSave={onSave}
            onDelete={onDelete}
          ></EditableTable>
        ) : (
          <>No orders found</>
        )}
      </div>
    </div>
  );
};

export default withAuthenticationRequired(OrdersView, {
  onRedirecting: () => <Loading />,
});
// export default withRelyantAuthenticationRequired(OrdersView);
