/* eslint-disable react/prop-types */
import React, { CSSProperties } from 'react';
import ReactMarkdown from 'react-markdown';

export const MarkdownText = ({
  markdownText,
  embeddedNode,
  style,
}: {
  markdownText;
  embeddedNode?;
  style?: CSSProperties;
}) => {
  return (
    <div>
      {/* Customize rendering with the 'components' prop */}
      <ReactMarkdown
        components={{
          p: ({ node, ...props }) => (
            <p {...props} style={style}>
              {props.children}
              {embeddedNode && embeddedNode}
            </p>
          ),
        }}
      >
        {markdownText}
      </ReactMarkdown>
    </div>
  );
};
