import { httpMethod, useApi } from './useApi';

export type Order = {
  id: string;
  user_id: string;
  product_id: string;
  sku: string;
  quantity: number;
  unit_price: number;
  total_price: number;
  currency: string;
  order_date_utc: Date;
};

export type RequestPOSTSchemaType = {};
export type RequestPUTSchemaType = { entity_id: string; fields_to_update: Order };
export type RequestDELETESchemaType = { entity_id: string };

export type ResponseGETSchema = { total: number; items: Order[] };
export type ResponsePOSTSchemaType = Order[];
export type ResponsePUTSchemaType = Order;
export type ResponseDELETESchemaType = string;

export const useCreateOrder = () => {
  const { call, data, error, ...rest } = useApi({
    authenticated: true,
    path: 'orders',
    httpMethod: httpMethod.POST,
    body: {},
  });
  const callFunction = (body: RequestPOSTSchemaType) => {
    call(body);
  };

  const responseData = data as ResponsePOSTSchemaType;
  return { call: callFunction, data: responseData, error, ...rest };
};

export const useGetOrders = () => {
  const { call, data, error, ...rest } = useApi({
    authenticated: true,
    path: 'orders',
    httpMethod: httpMethod.GET,
    body: {},
    queryParams: { limit: '1000' },
  });

  const responseData = data as ResponseGETSchema;
  return { call, data: responseData, error, ...rest };
};

export const useUpdateOrder = () => {
  const { call, data, error, ...rest } = useApi({
    authenticated: true,
    path: 'orders',
    httpMethod: httpMethod.PUT,
    body: {},
  });

  const callFunction = (body: RequestPUTSchemaType) => {
    call(body);
  };

  const responseData = data as ResponsePUTSchemaType;
  return { call: callFunction, data: responseData, error, ...rest };
};

export const useDeleteOrder = () => {
  const { call, data, error, ...rest } = useApi({
    authenticated: true,
    path: 'orders',
    httpMethod: httpMethod.DELETE,
    body: {},
  });

  const callFunction = (body: RequestDELETESchemaType) => {
    call(body);
  };

  const responseData = data as ResponseDELETESchemaType;
  return { call: callFunction, data: responseData, error, ...rest };
};
