import React, { useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router';

import Loading from '../../components/base/Loading';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import AssistantFlows from './AssistantFlows';
import { Tabs } from 'antd';
import { Text } from '../../components/base/Text';
import AssistantLogs from './AssistantLogs';

const { TabPane } = Tabs;

export const AssistantView = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const activeTabKey = location.pathname.split('/').pop() || 'flows';

  const handleTabChange = (key) => {
    navigate(`/assistant/${key}`);
  };

  return (
    <div style={{ padding: '3vw', overflowY: 'auto', gap: 24 }}>
      <Text value={'Assistant'} className="header1" />
      <Tabs
        defaultActiveKey={activeTabKey}
        activeKey={activeTabKey}
        onChange={handleTabChange}
        type="line"
        size="large"
      >
        <TabPane tab="Flows" key={'flows'}>
          <AssistantFlows />;
        </TabPane>
        <TabPane tab="Logs" key={'logs'}>
          <AssistantLogs />
        </TabPane>
      </Tabs>
    </div>
  );
};

export default withAuthenticationRequired(AssistantView, {
  onRedirecting: () => <Loading />,
});
// export default withRelyantAuthenticationRequired(AssistantView);
