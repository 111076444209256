import { httpMethod, useApi } from './useApi';

export type ResponseSchema = { access_token: string; refresh_token: string };
export type RequestPOSTSchemaType = { code: string; redirect_uri: string };

export const usePostToken = () => {
  const { call, data, error, ...rest } = useApi({
    authenticated: false,
    path: 'auth/exchange-token',
    httpMethod: httpMethod.POST,
    body: {},
  });

  const callFunction = (body: RequestPOSTSchemaType) => {
    call(body);
  };

  const responseData = data as ResponseSchema;
  return { call: callFunction, data: responseData, error, ...rest };
};

export const useGetIdentities = () => {
  const { call, data, error, ...rest } = useApi({
    authenticated: true,
    path: 'auth/identities',
    httpMethod: httpMethod.GET,
  });

  const responseData = data as { provider: string; enabled: boolean }[];
  return { call, data: responseData, error, ...rest };
};

export const usePostIdentities = () => {
  const { call, data, error, ...rest } = useApi({
    authenticated: true,
    path: 'auth/identities',
    httpMethod: httpMethod.POST,
  });

  const callFunction = (body: { provider: string; primary_user: string; secondary_user: string }) => {
    call(body);
  };

  const responseData = data as { provider: string; enabled: boolean }[];
  return { call: callFunction, data: responseData, error, ...rest };
};

export const useDeleteIdentities = () => {
  const { call, data, error, ...rest } = useApi({
    authenticated: true,
    path: 'auth/identities',
    httpMethod: httpMethod.DELETE,
  });

  const callFunction = (body: { provider: string }) => {
    call(body);
  };

  const responseData = data as { provider: string; enabled: boolean }[];
  return { call: callFunction, data: responseData, error, ...rest };
};
