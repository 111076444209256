import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router';

import { Text } from '../../components/base/Text';
import { ListItems } from '../../components/base/ListItems';
import { Button } from '../../components/base/Button';

import FlowEditor from './FlowEditor';
import { isNonEmpty } from '../../utils/arrayUtils';
import { Flow, useCreateFlow, useDeleteFlow, useGetFlows, useUpdateFlow } from '../../networking/useFlow';
import Loading from '../../components/base/Loading';
import { withAuthenticationRequired } from '@auth0/auth0-react';

export const AssistantFlows = () => {
  const navigate = useNavigate();
  const { isLoading: isLoadingDelete, data: deleteFlowsResponse, call: callDeleteFlow } = useDeleteFlow();
  const { isLoading, data: postFlowsResponse, call: callPostFlow } = useCreateFlow();
  const { isLoading: isLoadingUpdate, data: putFlowsResponse, call: callUpdateFlow } = useUpdateFlow();

  const [searchParams] = useSearchParams();
  const flowIdParam = searchParams.get('flow-id');

  const { isLoading: isLoadingGetFlows, call: callGetFlows, data: flowsResponse } = useGetFlows({});
  const {
    isLoading: isLoadingGetFlow,
    call: callGetFlow,
    data: flowsParamResponse,
  } = useGetFlows({ ids: [flowIdParam] });

  const [flows, setFlows] = useState<Flow[]>([]);
  const [flowListItems, setFlowListItems] = useState([]);
  const [selectedFlow, setSelectedFlow] = useState<Flow>(null);

  const defaultNewFlow: Flow = {
    name: 'Insert flow name',
    description: 'Insert flow description',
    picture: undefined,
    step_slugs: [],
  };

  useEffect(() => {
    const flowListItems = [];
    for (let flow of flows) {
      flowListItems.push({
        header: flow.name,
        subheader: flow.description,
        loading: false,
        buttons: [
          <Button
            text={'Delete'}
            key={`delete-flow-button`}
            onClick={() => {
              callDeleteFlow({ entity_id: flow.id });
            }}
          />,
        ],
        onClick: () => {
          navigate(`/assistant/flows?flow-id=${flow.id}`);
          setSelectedFlow(flow);
        },
      });
    }
    setFlowListItems(flowListItems);
  }, [flows]);

  /**
   * Callback after deletion operation
   */
  useEffect(() => {
    if (deleteFlowsResponse) {
      const newFlows = flows.filter((prevFlow) => prevFlow.id !== deleteFlowsResponse);
      setFlows(newFlows);
      setSelectedFlow(null);
    }
  }, [deleteFlowsResponse]);

  /**
   * Callback after creation request
   */
  useEffect(() => {
    if (postFlowsResponse) {
      setFlows((prevFlows) => [...prevFlows, ...postFlowsResponse]);
      setSelectedFlow(postFlowsResponse[0]);
    }
  }, [postFlowsResponse]);

  /**
   * Callback after update request
   */
  useEffect(() => {
    if (putFlowsResponse) {
      const updatedFlows = flows.map((oldFlow) => (oldFlow.id === putFlowsResponse.id ? putFlowsResponse : oldFlow));
      setFlows(updatedFlows);
      setSelectedFlow(putFlowsResponse);
    }
  }, [putFlowsResponse]);

  /**
   * Callback after read request
   */
  useEffect(() => {
    if (flowsResponse && isNonEmpty(flowsResponse.items)) {
      setFlows(flowsResponse.items);
    }
  }, [flowsResponse]);

  /**
   * Callback after read single flow request
   */
  useEffect(() => {
    if (flowsParamResponse && isNonEmpty(flowsParamResponse.items)) {
      setSelectedFlow(flowsParamResponse.items[0]);
    }
  }, [flowsParamResponse]);

  useEffect(() => {
    callGetFlows();
  }, []);

  useEffect(() => {
    if (flowIdParam) {
      if (isNonEmpty(flows)) {
        const filteredFlow = flows.filter((f) => f.id === flowIdParam);
        setSelectedFlow(filteredFlow[0]);
      } else {
        callGetFlow();
      }
    }
  }, [flowIdParam]);

  const onCreate = () => {
    callPostFlow({ entities: [defaultNewFlow] });
  };

  const onUpdate = (entityId, data) => {
    callUpdateFlow({ entity_id: entityId, fields_to_update: data });
  };

  return (
    <>
      <div style={{ display: 'flex', minHeight: '80vh' }}>
        {/* Left Panel: Flow List */}
        <div style={{ flex: 1, padding: '1rem' }}>
          <Text value={'Flows'} className="header2" />
          {isLoadingGetFlows ? (
            <Loading absolute={false} />
          ) : isNonEmpty(flowListItems) ? (
            <ListItems items={flowListItems} itemLayout="horizontal"></ListItems>
          ) : null}

          {isLoadingGetFlows ? null : (
            <Button isLoading={isLoading} text="Create new flow" style={{ marginTop: 16 }} onClick={onCreate} />
          )}
        </div>

        {/* Right Panel: Flow Editor */}
        {selectedFlow ? (
          <div
            style={{
              flex: 2,
              transition: 'transform 0.4s ease-in-out',
              transform: selectedFlow ? 'translateX(0)' : 'translateX(100%)',
              backgroundColor: 'white',
              padding: '2rem',
              boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
              borderRadius: 8,
            }}
          >
            {selectedFlow ? (
              <FlowEditor
                flow={selectedFlow}
                onUpdate={onUpdate}
                onClose={() => setSelectedFlow(null)}
                isEditing={true}
              />
            ) : null}
          </div>
        ) : null}
      </div>
    </>
  );
};

export default withAuthenticationRequired(AssistantFlows, {
  onRedirecting: () => <Loading />,
});
// export default withRelyantAuthenticationRequired(AssistantView);
