import React, { CSSProperties } from 'react';
import { LoadingSpinner } from '../../icons/LoadingSpinner';

const Loading = ({
  text,
  style,
  absolute = true,
  onlyText = false,
}: {
  text?: string;
  style?: CSSProperties;
  absolute?: boolean;
  onlyText?: boolean;
}) => {
  if (onlyText) {
    return <div style={{ fontSize: style ? style.fontSize : '2vw' }}>{text}</div>;
  }
  return (
    <div
      className={absolute ? 'spinner' : ''}
      style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', ...style }}
    >
      <div style={{ fontSize: style ? style.fontSize : '2vw' }}>{text}</div>
      <div>
        <LoadingSpinner></LoadingSpinner>
      </div>
    </div>
  );
};

export default Loading;
