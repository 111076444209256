import { httpMethod, useApi } from './useApi';

export type Step = {
  id: string;
  slug: string;
  text: string;
  description?: string;
  ui_description?: string;
  type: 'event' | 'action';
  prompt?: string;
  iconUrl?: string;
  service?: string;
  version?: string;
  function?: Record<string, any>;
};

export type Flow = {
  id?: string;
  name: string;
  description: string;
  picture?: string;
  step_slugs: string[];
};

export type RequestPOSTSchemaType = {};
export type RequestPUTSchemaType = {};
export type RequestDELETESchemaType = {};

export type ResponseGETSchema = { total: number; items: Flow[] };
export type ResponsePOSTSchemaType = Flow[];
export type ResponsePUTSchemaType = Flow;
export type ResponseDELETESchemaType = string;

export const useCreateFlow = () => {
  const { call, data, error, ...rest } = useApi({
    authenticated: true,
    path: 'flows',
    httpMethod: httpMethod.POST,
    body: {},
  });
  const callFunction = (body: RequestPOSTSchemaType) => {
    call(body);
  };

  const responseData = data as ResponsePOSTSchemaType;
  return { call: callFunction, data: responseData, error, ...rest };
};

export const useGetFlows = (queryParams?) => {
  const { call, data, error, ...rest } = useApi({
    authenticated: true,
    path: 'flows',
    httpMethod: httpMethod.GET,
    body: {},
    queryParams: queryParams,
  });

  const responseData = data as ResponseGETSchema;
  return { call, data: responseData, error, ...rest };
};

export const useUpdateFlow = () => {
  const { call, data, error, ...rest } = useApi({
    authenticated: true,
    path: 'flows',
    httpMethod: httpMethod.PUT,
    body: {},
  });

  const callFunction = (body: RequestPUTSchemaType) => {
    call(body);
  };

  const responseData = data as ResponsePUTSchemaType;
  return { call: callFunction, data: responseData, error, ...rest };
};

export const useDeleteFlow = () => {
  const { call, data, error, ...rest } = useApi({
    authenticated: true,
    path: 'flows',
    httpMethod: httpMethod.DELETE,
    body: {},
  });

  const callFunction = (body: RequestDELETESchemaType) => {
    call(body);
  };

  const responseData = data as ResponseDELETESchemaType;
  return { call: callFunction, data: responseData, error, ...rest };
};

export const useGetFlowPredefinedSteps = () => {
  const { call, data, error, ...rest } = useApi({
    authenticated: true,
    path: 'flows/steps',
    httpMethod: httpMethod.GET,
    body: {},
    queryParams: {},
  });

  const responseData = data as Step[];
  return { call, data: responseData, error, ...rest };
};
