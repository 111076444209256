import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';

import { Text } from '../../components/base/Text';
import { ListItems } from '../../components/base/ListItems';

import { isNonEmpty } from '../../utils/arrayUtils';

import Loading from '../../components/base/Loading';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { FlowLog, useGetFlowLogs } from '../../networking/useFlowLogs';
import { ReloadOutlined } from '@ant-design/icons';

export const AssistantLogs = () => {
  const navigate = useNavigate();
  const { isLoading, call: callGetFlows, data: logsResponse } = useGetFlowLogs();
  const [logs, setLogs] = useState<FlowLog[]>([]);
  const [flowListItems, setFlowListItems] = useState([]);

  useEffect(() => {
    console.log(logs);
    const flowListItems = [];
    for (let log of logs) {
      const localDate = new Date(log.created_utc).toLocaleString();
      flowListItems.push({
        header: log.message.message,
        subheader: localDate,
        loading: false,
        onClick: () => {
          if (log.flow_id) {
            navigate(`/assistant/flows?flow-id=${log.flow_id}`);
          }
        },
      });
    }
    setFlowListItems(flowListItems);
  }, [logs]);

  /**
   * Callback after read operation
   */
  useEffect(() => {
    if (logsResponse && isNonEmpty(logsResponse.items)) {
      setLogs(logsResponse.items);
    }
  }, [logsResponse]);

  useEffect(() => {
    callGetFlows();
  }, []);

  return (
    <>
      <div style={{ display: 'flex', minHeight: '80vh' }}>
        {/* Left Panel: Flow List */}
        <div style={{ flex: 1, padding: '1rem' }}>
          <div className="flex-row" style={{ justifyContent: 'space-between' }}>
            <Text value={'Logs'} className="header2" />
            <ReloadOutlined
              onClick={() => {
                callGetFlows();
              }}
            />
          </div>

          {isLoading ? (
            <Loading absolute={false} />
          ) : isNonEmpty(flowListItems) ? (
            <ListItems items={flowListItems} itemLayout="horizontal"></ListItems>
          ) : (
            <Text value="No logs found" />
          )}
        </div>

        {/* Right Panel: Flow Log viewer */}
        {/* {selectedFlowLog ? (
          <div
            style={{
              flex: 2,
              transition: 'transform 0.4s ease-in-out',
              transform: selectedFlowLog ? 'translateX(0)' : 'translateX(100%)',
              backgroundColor: 'white',
              padding: '2rem',
              boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
              borderRadius: 8,
            }}
          >
            {selectedFlowLog ? <div>Flow Log open</div> : null}
          </div>
        ) : null} */}
      </div>
    </>
  );
};

export default withAuthenticationRequired(AssistantLogs, {
  onRedirecting: () => <Loading />,
});
