import React from 'react';
import { FormView } from '../../components/base/FormView';
import { FlowForm } from './FlowForm';

import { CloseOutlined } from '@ant-design/icons';

const FlowEditor = ({
  flow,
  onCreate,
  onUpdate,
  onClose,
  isEditing,
}: {
  flow;
  onCreate?: (data) => void;
  onUpdate?: (entityId, data) => void;
  onClose;
  isEditing;
}) => {
  const displayMapping = {
    name: 'Name',
    description: 'Description',
    step_slugs: 'Steps',
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <div style={{}}>
      <div className="flex-row" style={{ justifyContent: 'flex-end' }}>
        <CloseOutlined onClick={handleClose}></CloseOutlined>
      </div>

      <div className="flex-column" style={{ paddingTop: 24 }}>
        <FormView
          headerForm={flow.name}
          data={flow}
          displayMapping={displayMapping}
          onUpdate={onUpdate}
          onCreate={onCreate}
          isLoading={false}
          customForm={<FlowForm entity={flow} />}
          isEditingForm={isEditing}
        />
      </div>
    </div>
  );
};

export default FlowEditor;
