import { httpMethod, useApi } from './useApi';

export type FlowLogMessage = {
  message: string;
  extra: Record<string, any>;
};

export type FlowLog = {
  id?: string;
  user_id: string;
  flow_id: string;
  message: FlowLogMessage;
  created_utc: string;
};

export type RequestPOSTSchemaType = {};
export type RequestPUTSchemaType = { entity_id: string; fields_to_update: FlowLog };
export type RequestDELETESchemaType = { entity_id: string };

export type ResponseGETSchema = { total: number; items: FlowLog[] };
export type ResponsePOSTSchemaType = FlowLog[];
export type ResponsePUTSchemaType = FlowLog;
export type ResponseDELETESchemaType = string;

export const useCreateFlowLog = () => {
  const { call, data, error, ...rest } = useApi({
    authenticated: true,
    path: 'flow-logs',
    httpMethod: httpMethod.POST,
    body: {},
  });
  const callFunction = (body: RequestPOSTSchemaType) => {
    call(body);
  };

  const responseData = data as ResponsePOSTSchemaType;
  return { call: callFunction, data: responseData, error, ...rest };
};

export const useGetFlowLogs = () => {
  const { call, data, error, ...rest } = useApi({
    authenticated: true,
    path: 'flow-logs',
    httpMethod: httpMethod.GET,
    body: {},
    queryParams: {},
  });

  const responseData = data as ResponseGETSchema;
  return { call, data: responseData, error, ...rest };
};

export const useUpdateFlowLog = () => {
  const { call, data, error, ...rest } = useApi({
    authenticated: true,
    path: 'flow-logs',
    httpMethod: httpMethod.PUT,
    body: {},
  });

  const callFunction = (body: RequestPUTSchemaType) => {
    call(body);
  };

  const responseData = data as ResponsePUTSchemaType;
  return { call: callFunction, data: responseData, error, ...rest };
};

// export const useDeleteFlowLog = () => {
//   const { call, data, error, ...rest } = useApi({
//     authenticated: true,
//     path: 'flow-logs',
//     httpMethod: httpMethod.DELETE,
//     body: {},
//   });

//   const callFunction = (body: RequestDELETESchemaType) => {
//     call(body);
//   };

//   const responseData = data as ResponseDELETESchemaType;
//   return { call: callFunction, data: responseData, error, ...rest };
// };
