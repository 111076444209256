import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router';

import NavBar from './components/NavBar';
import Support from './views/Support/Support';

import './App.css';
import initFontAwesome from './utils/initFontAwesome';
import ResearchView from './views/ResearchView/ResearchView';
import ReportGenerationView from './views/ReportGeneration/ReportGeneration';
import ReviewView from './views/ReportReview/Review';
import ProfileView from './views/Profile/ProfileView';
import AdminView from './views/Admin/AdminView';
import IntegrationView from './views/IntegrationView/IntegrationView';
import Callback from './views/Login/Login';
import AssistantView from './views/Assistant/Assistant';
import OrdersView from './views/OrdersView/OrdersView';

initFontAwesome();

const App = () => {
  return (
    <Router>
      <div id="app">
        <NavBar>
          <div className="" style={{}}>
            <Routes>
              <Route path="/callback" isAuthenticated component={<Callback />} />
              <Route path="/" exact element={<ReviewView />} />
              <Route path="/integrations" element={<IntegrationView />} />
              <Route path="/assistant" element={<Navigate to="/assistant/flows" />} />
              <Route path="assistant/flows" element={<AssistantView />} />
              <Route path="assistant/logs" element={<AssistantView />} />
              <Route path="/orders" element={<OrdersView />} />
              <Route path="/profile" element={<ProfileView />} />
              <Route path="/report-generation" element={<ReportGenerationView />} />
              <Route path="/report-review" element={<ReviewView />} />
              <Route path="/research" element={<ResearchView />} />
              <Route path="/admin" element={<AdminView />} />
              <Route path="/support" element={<Support />} />
            </Routes>
          </div>
          {/* <Footer /> */}
        </NavBar>
      </div>
    </Router>
  );
};

export default App;
