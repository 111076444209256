import React from 'react';
import { Text } from '../../components/base/Text';

import { DeleteFilled } from '@ant-design/icons';
import { Select } from 'antd';
import { Step } from '../../networking/useFlow';

const { Option } = Select;

/**
 * A Relyant flow step starts with an event item, and it is followed by actions.
 * There is a set of predefined events that are supported by the application,
 * and the action can be more freely defined by the users.
 *
 * @returns
 */
export const FlowStep = ({
  predefinedSteps,
  step,
  isEditing,
  setStep,
  deleteStep,
}: {
  predefinedSteps: Step[];
  step: Step;
  isEditing: boolean;
  setStep;
  deleteStep;
}) => {
  return (
    <>
      <div className="flex-row" style={{ gap: 12, justifyContent: 'flex-start' }}>
        <>
          <Text
            value={step.type === 'event' ? 'Event:' : 'Action:'}
            style={{ fontWeight: 700, color: 'black', minWidth: 64 }}
          />

          <Select
            onSelect={(option) => {
              const newStep = predefinedSteps.filter((predefinedStep) => predefinedStep.id === option);
              setStep(newStep[0].slug);
            }}
            defaultValue={step.text}
            disabled={!isEditing}
            showSearch
            style={{ width: 320 }}
            placeholder="Select an option"
            optionFilterProp="label"
            optionLabelProp="label"
            filterOption={(input, option) => option?.label.toString().toLowerCase().includes(input.toLowerCase())}
          >
            {predefinedSteps
              .filter((predefinedStep) => predefinedStep.type === step.type)
              .map((option) => (
                <Option key={option.id} value={option.id} label={option.text}>
                  <div style={{}}>
                    <div className="flex-row" style={{ alignItems: 'center', gap: '8px' }}>
                      {option.iconUrl ? <img src={option.iconUrl} alt=""></img> : null}
                      {option.text}
                    </div>
                    <div style={{ fontSize: 'small', color: 'grey' }}>{option.ui_description}</div>
                  </div>
                </Option>
              ))}
          </Select>

          {isEditing ? <DeleteFilled style={{ color: 'red' }} onClick={deleteStep} /> : null}
        </>
      </div>
    </>
  );
};
